import { capitalizeFirstLetter } from "@/utils/utils.js";

const parseJsonValue = (text) => {
  var result = text;
  if (typeof text === "string") {
    //console.log(text);
    try {
      var obj = JSON.parse(text);

      result = returnTable(obj);
    } catch {
      //Ignore the error and return text.
      //console.log(e);
    }
  }
  else if (typeof text === "object") {
    result = returnTable(text);
  }

  return result;
}

const returnTable = (object) => {
  //console.log(typeof object === "object", Array.isArray(object), object);
  var result = object;
  if (typeof object === "object") {

    if (Array.isArray(object)) {
      result = returnArrayTable(object);
    }
    else {
      result = returnObjectTable(object);
    }
  }

  return result;
}

const returnObjectTable = (dict) => {

  let result = "<table class='border border-slate-400 border-solid'><tbody>";
  for (var property in dict) {
    result += "<tr class='m-1 p-1 row'>"
    result += `<td class="font-bold key bg-white dark:dark:bg-surface-800 dark:text-white/80">${capitalizeFirstLetter(property)}</td>`;
    result += '<td class="font-bold key bg-white dark:dark:bg-surface-800 dark:text-white/80">:</td>';
    result += `<td class="m-1 p-1 bg-violet-100 dark:text-slate-600">${parseJsonValue(dict[property])}</td>`;
    result += "</tr>"
  }
  result += "</tbody></table>";
  return result;
}

const returnArrayTable = (array) => {
  let result = "";
  var property;
  var item;
  var key;
  //console.log("fnReturnArrayTable... array.length - ", array.length);
  if (array.length > 0) {
    let firstItem = array[0];
    //console.log("fnReturnArrayTable... firstItem - ", typeof firstItem === "object", Array.isArray(firstItem), firstItem);
    //if (typeof firstItem === "object" && Array.isArray(firstItem)) {}
    if (typeof firstItem === "object") {
      result = "<table class='stellio-details'><thead><tr>";
      for (property in firstItem) {
        result += `<th>${capitalizeFirstLetter(property)}</th>`;
      }
      result += "</tr></thead>";
      result += "<tbody>";
      for (key in array) {
        result += "<tr>";
        item = array[key];
        for (property in item) {
          result += `<td>${parseJsonValue(item[property]) }</td>`;
        }
        result += "</tr>";
      }
      result += "</tbody></table>";
    }
    else {
      //result = JSON.stringify(array);
      result = array.join(", ")
    }
  }
  //console.log("fnReturnArrayTable... result - ", result);
  return result;
}

export {
  parseJsonValue
};

