<template>
    <form id="frmTask" name="frm-task" ref="frmTask">
        <PrimeDialog v-model:visible="visible" modal header="Assign concrete batch to task." @hide="fnCancel"
                     :draggable="false" :style="{ width: '30rem' }"
                     :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
            <div role="status" v-if="isLoading">
                <loader-mini-component></loader-mini-component>
            </div>
            <table class="table-auto w-full mb-2">
                <thead class="text-indigo-800">
                    <tr>
                        <th class="text-bold bg-surface-200 text-indigo-600 border border-surface-400 p-1 dark:bg-primary-400/30 dark:text-indigo-100">Produced</th>
                        <th class="text-bold bg-surface-200 text-indigo-600 border border-surface-400 p-1 dark:bg-primary-400/30 dark:text-indigo-100">Used</th>
                        <th class="text-bold bg-surface-200 text-indigo-600 border border-surface-400 p-1 dark:bg-primary-400/30 dark:text-indigo-100">Remaining</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="border border-surface-400 p-1 text-center">{{ fnFormatNumber(batchEntity.quantity.produced) }}</td>
                        <td class="border border-surface-400 p-1 text-center">{{ fnFormatNumber(batchEntity.quantity.used) }}</td>
                        <td class="border border-surface-400 p-1 text-center">{{ fnFormatNumber(batchEntity.quantity.remaining) }}</td>
                    </tr>
                </tbody>
            </table>
            <div class="mb-2">
                <div v-if="v$.entity.quantity.$error" class="text-red-600 text-sm mb-2">
                    Please enter the aproximate quantity used.<br />(a valid positive number greater than 0).
                </div>
                Assign Quantity:
                <InputNumber v-model="entity.quantity" placeholder="Quantity" inputClass="w-36" /> {{batchEntity.unit}}
            </div>
            <div class="mb-2">
                <div v-if="v$.entity.jobId.$error" class="text-red-600 text-sm mb-2">Please select a Job.</div>
                <Dropdown v-model="entity.jobId" :options="jobList" optionLabel="name" optionValue="id" placeholder="Please Select one"
                          class="w-full" :invalid="v$.entity.jobId.$error" @change="fnJobChanged" />
            </div>
            <div class="mb-2">
                <div v-if="v$.entity.task.$error" class="text-red-600 text-sm mb-2">Please select a Task.</div>
                <Dropdown v-model="entity.task" :options="taskList" optionLabel="task" optionValue="task" placeholder="Please Select one"
                          class="w-full" :invalid="v$.entity.task.$error" @change="fnTaskChanged" />
            </div>
            <table class="table-auto w-full mb-2" v-if="batchList && batchList.length>0">
                <thead class="text-indigo-800">
                    <tr>
                        <th class="text-bold bg-surface-200 text-indigo-600 border border-surface-400 p-1 dark:bg-primary-400/30 dark:text-indigo-100" colspan="3">
                            Currently assigned.
                        </th>
                    </tr>
                    <tr>
                        <th class="text-bold bg-surface-200 text-indigo-600 border border-surface-400 p-1 dark:bg-primary-400/30 dark:text-indigo-100">Batch</th>
                        <th class="text-bold bg-surface-200 text-indigo-600 border border-surface-400 p-1 dark:bg-primary-400/30 dark:text-indigo-100">Quantity</th>
                        <th class="text-bold bg-surface-200 text-indigo-600 border border-surface-400 p-1 dark:bg-primary-400/30 dark:text-indigo-100">Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in batchList"  v-bind:key="index">
                        <td class="border border-surface-400 p-1 text-center">{{ item.batchName }}</td>
                        <td class="border border-surface-400 p-1 text-center">{{ fnFormatNumber(item.quantity) }}</td>
                        <td class="border border-surface-400 p-1 text-center">{{ item.timestamp }}</td>
                    </tr>
                </tbody>
            </table>

            <template #footer>
                <Button label="Cancel" title="Cancel and go back" severity="secondary" class="mr-2"
                        @click="fnCancel" icon="pi pi-times-circle" iconPos="left"></Button>
                <Button label="Assign" title="Assign Batch to Task" @click="fnAssign"
                        icon="pi pi-plus-circle" iconPos="right" />
            </template>
        </PrimeDialog>
    </form>
</template>

<script>
    import Button from 'primevue/button';
    import PrimeDialog from "primevue/dialog";
    import InputNumber from "primevue/inputnumber";
    import Dropdown from "primevue/dropdown";
    
    import { ToastSeverity } from 'primevue/api';
    import { useVuelidate } from '@vuelidate/core'
    import { required, minValue, decimal } from '@vuelidate/validators'

    import { stellioService } from "@/services/StellioService.js";
    import { concreteBatchService } from "@/services/ConcreteBatchService.js";
    import { capitalizeFirstLetter, formatNumber } from "@/utils/utils.js";
    
    import { stringHasValue } from "@/utils/utils.js";
    import Constants from "@/utils/Constants.js";

    import LoaderMiniComponent from '@/components/LoaderMiniComponent.vue'

    export default {
        name: "AssignTaskComponent",
        emits: ["onError", "onClose", "onAssignTask"],
        setup() {
            return { v$: useVuelidate({ $scope: "assign-task" }) }
        },
        components: {
            Button,
            PrimeDialog,
            InputNumber,
            Dropdown,
            LoaderMiniComponent
        },
        props: {
            batchEntity: Object,
            isOpen: Boolean
        },
        data() {
            return {
                isLoading: false,
                entity: {
                    batchId: undefined,
                    user: undefined,
                    jobId: undefined,
                    jobName: undefined,
                    task: undefined,
                    quantity: undefined,
                    timestamp: undefined
                },
                textHeader: "",
                visible: false,
                jobList: [],
                taskList: [],
                batchList: []
            }
        },
        validations() {
            return {
                entity: {
                    jobId: { required },
                    task: { required },
                    quantity: { required, minValue: minValue(1), decimal }
                }
            }
        },
        watch: {
            isOpen: function (val) {
                if (val == true) {                    
                    this.isLoading = true;

                    console.log("Add Working hours Component Opened!");
                    this.entity.batchId = this.batchEntity.batchId;
                    this.entity.projectId = this.$store.getters.project;
                    this.entity.user = this.$store.getters.username;
                    this.visible = this.isOpen;
                    this.textHeader = "Assing Concrete batch '" + this.batchEntity.batch + "' to a task.";
                    this.fnLoadJobs();
                }
            }
        },
        methods: {
            fnLoadJobs() {
                stellioService.stellio(this.entity.projectId, Constants.StellioEntities.JOBS)
                    .then((data) => {
                        //console.log(JSON.stringify(data));
                        this.jobList = data;
                        this.isLoading = false;
                    })
                    .catch((error) => {
                        console.log(error);
                        this.$emit('onError', 'Error Loading', 'An error occurred when trying to load the Jobs from Stellio!!!', ToastSeverity.ERROR);
                        this.isLoading = false;
                    });
            },
            fnJobChanged(event) {

                let job = this.jobList.find(o => o.id === event.value);
                this.entity.jobName = job.name;
                
                
                this.entity.task = undefined;
                this.taskList = [];
                if (Object.hasOwn(job, 'subTasks')) {
                    this.taskList = JSON.parse(job.subTasks);
                    //console.log(this.taskList);
                }
                
                this.fnTaskChanged();
            },
            fnTaskChanged(event) {
                
                this.batchList = [];
                if (event !== undefined && stringHasValue(event.value)) {
                    
                    let task = this.taskList.find(o => o.task === event.value);
                    console.log(task);
                    if (task != undefined && Array.isArray(task.batches))
                    {
                        this.batchList = task.batches;
                    }
                }
            },
            fnAssign() {
                this.v$.$validate()
                    .then(result => {
                        //console.log(this.v$.alertObj.name.$errors);
                        
                        console.log(JSON.stringify(this.entity));
                        
                        if (result) {
                            this.isLoading = true;
                            this.entity.user = this.$store.getters.username;

                            concreteBatchService.assignTask(this.entity, this.taskList)
                                .then((data) => {
                                    //console.log(data);
                                    this.entity.timestamp = data.timestamp;
                                    this.$emit('onAssignTask', this.entity, this.batchEntity.batch);
                                    
                                    let stellio = {
                                        jobId: this.entity.jobId,
                                        subTasks: JSON.stringify(this.taskList),
                                        task: this.entity.task,
                                        batch: this.batchEntity.batch,
                                        quantity: this.entity.quantity,
                                        timestamp: data.timestamp
                                    };

                                    concreteBatchService.assignConcrete(stellio)
                                        .then((data) => {
                                            console.log(data);
                                            this.isLoading = false;
                                            this.fnCancel();
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                            this.$emit('onError', 'Error saving.', 'An error occurred when trying to update Stellio Assing the concrete batch "' + this.batchEntity.batch + '" to a task!!!', ToastSeverity.ERROR);
                                            this.isLoading = false;
                                            this.fnCancel();
                                        });
                                })
                                .catch((error) => {
                                    console.log(error);
                                    this.$emit('onError', 'Error saving.', 'An error occurred when trying to Assing the concrete batch "' + this.batchEntity.batch + '" to a task!!!', ToastSeverity.ERROR);
                                    this.isLoading = false;
                                });
                        }
                        //else {
                        //    console.log(this.v$.alertObj.name);
                        //}

                        return false;
                    })
                    .catch(error => {
                        console.log(error);
                    });
                
                return false;
            },
            fnCancel() {
                this.fnCleanForm();
                this.visible = false;
                this.textHeader = "";
                this.$emit('onClose');
            },
            fnCleanForm: function () {
                this.entity = {
                    batchId: undefined,
                    projectId: undefined,
                    jobId: undefined,
                    jobName: undefined,
                    task: undefined,
                    quantity: undefined,
                    timestamp: undefined
                };
                this.$refs.frmTask.reset();
                this.v$.$reset();
            },
            fnCapText(text) {
                return capitalizeFirstLetter(text);
            },
            fnFormatNumber(number) {
                return formatNumber(number);
            },
            fnFormatDateTime(date) {
                
                if (stringHasValue(date))
                {
                    return date.replace("T", " ").substring(0, date.indexOf("."));
                }
                else
                {
                    return date;
                }
            },
        }
    };
</script>
<style scoped>


</style>

