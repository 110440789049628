<template>
    <div class="flex align-middle px-5">
        <h2 class="text-4xl leading-tight text-center m-auto text-bold">
            Assets Manager
        </h2>
        <Button type="button" label="ASSET" icon="pi pi-plus-circle" iconPos="right"
                class="ml-auto" title="Add a new Asset" @click="fnAddNew"></Button>
    </div>
    <div class="px-5 py-3 flex-auto">
        <div class="border-2 p-2 shadow-md table-widget rounded-md border-indigo-400">
            <h3 class="text-indigo-600 text-center text-xl"
                style="text-transform: uppercase; font-weight: bold">
                ASSETS
            </h3>
            <DataTable :value="tableData"
                       stripedRows
                       class="w-full"
                       size="small"
                       paginator
                       :rows="10"
                       :rowsPerPageOptions="[10, 20, 30, 40, 50, 100]"
                       scrollable
                       sortField="createdAt"
                       :sortOrder="-1"
                       v-model:filters="filters"
                       filterDisplay="row"
                       :loading="isLoading"
                       :globalFilterFields="filterFields">
                <template #empty>
                    No assets found.
                </template>
                <template #loading>
                    Loading assets data. Please wait...
                </template>
                <Column :exportable="false" bodyClass="dark:text-surface-200">
                    <template #body="slotProps">
                        <Button icon="pi pi-chart-bar" outlined rounded size="small"
                                title="Details" class="dark:text-primary-300 dark:border-primary-300"
                                @click="fnOpenGraph(slotProps.data)" />
                    </template>
                </Column>
                <Column :exportable="false" bodyClass="dark:text-surface-200">
                    <template #body="slotProps">
                        <Button icon="pi pi-search" outlined rounded size="small"
                                title="Asset Details" class="dark:text-primary-300 dark:border-primary-300"
                                @click="fnDetailsOpen(slotProps.data.assetId, slotProps.data.name)" />
                    </template>
                </Column>
                <Column field="companyId" header="Id" sortable bodyClass="dark:text-surface-200">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value"
                                   type="text"
                                   @input="filterCallback"
                                   class="w-32"
                                   placeholder="Search by Id"
                                   size="small" />
                    </template>
                </Column>
                <Column field="name" header="Asset" sortable bodyClass="dark:text-surface-200">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value"
                                   type="text"
                                   @input="filterCallback"
                                   class="w-[16rem]"
                                   placeholder="Search by name"
                                   size="small" />
                    </template>
                </Column>
                <Column field="assetType" header="Type" sortable bodyClass="dark:text-surface-200">
                    <template #body="{ data }">
                        {{ fnCapText(data.assetType) }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value"
                                   type="text"
                                   @input="filterCallback"
                                   class="w-28"
                                   placeholder="Type"
                                   size="small" />
                    </template>
                </Column>
                <Column field="lifetimeSpan"
                        header="Maximum (Hr)"
                        dataType="numeric"
                        sortable
                        bodyClass="dark:text-surface-200">
                    <template #body="{ data }">
                        {{ fnFormatNumber(data.lifetimeSpan) }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputNumber v-model="filterModel.value"
                                     @input="filterCallback"
                                     inputClass="w-[6rem] p-2"
                                     size="small" />
                    </template>
                </Column>
                <Column field="operationalTime"
                        header="Operational (Hr)"
                        dataType="numeric"
                        sortable
                        bodyClass="dark:text-surface-200">
                    <template #body="{ data }">
                        {{ fnFormatNumber(data.operationalTime) }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputNumber v-model="filterModel.value"
                                     @input="filterCallback"
                                     inputClass="w-[6rem] p-2"
                                     size="small" />
                    </template>
                </Column>
                <Column field="lifetimeLeft"
                        header="Remaining (Hr)"
                        dataType="numeric"
                        sortable
                        bodyClass="dark:text-surface-200">
                    <template #body="{ data }">
                        {{ fnFormatNumber(data.lifetimeLeft) }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputNumber v-model="filterModel.value"
                                     @input="filterCallback"
                                     inputClass="w-[6rem] p-2"
                                     size="small" />
                    </template>
                </Column>
                <Column field="lifetimeEstimated"
                        header="Predicted (Hr)"
                        dataType="numeric"
                        sortable
                        bodyClass="dark:text-surface-200">
                    <template #body="{ data }">
                        {{ fnFormatNumber(data.lifetimeEstimated) }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputNumber v-model="filterModel.value"
                                     @input="filterCallback"
                                     inputClass="w-[6rem] p-2"
                                     size="small" />
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>
    <LoaderComponent :isLoading="isLoading" type="primary"></LoaderComponent>
    <PrimeDialog v-model:visible="showModel"
                 modal
                 v-model:header="modelHeader"
                 :style="{ width: '50rem' }"
                 :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
        <div>
            Failure Probability:
            <span class="font-medium">{{ failureProbability }}</span>
        </div>
        <img width="600" :src="imageUrl" alt="Asset Type Forecast" />
    </PrimeDialog>

    <asset-details-component :assetId="dispose.assetId"
                             :assetName="dispose.assetName"
                             :isOpen="dispose.isOpen"
                             @onWorkAdded="fnWorkAdded"
                             @onWorkRemoved="fnWorkRemoved"
                             @onError="fnShowToast"
                             @onDispose="fnDisposeAsset"
                             @onClose="fnDetailsClose" />
    <asset-new-component     :isOpen="newAsset.isOpen"
                             @onSave="fnNewAdded"
                             @onError="fnShowToast"
                             @onClose="fnNewClose" />
</template>
<script>
import LoaderComponent from "@/components/LoaderComponent.vue";
import AssetDetailsComponent from "@/components/Assets/AssetDetailsComponent";
import AssetNewComponent from "@/components/Assets/AssetNewComponent";

import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import PrimeDialog from "primevue/dialog";

import { ToastSeverity } from "primevue/api";
import { FilterMatchMode } from "primevue/api";

import { assetService } from "@/services/AssetService.js";
import {
  capitalizeFirstLetter,
  formatNumber,
  formatPercentage,
} from "@/utils/utils.js";


import axios from "axios";
//import Constants from "../utils/Constants.js";

export default {
  name: "AssetManagementPage",
  components: {
    AssetDetailsComponent,
    AssetNewComponent,
    LoaderComponent,
    DataTable,
    Column,
    Button,
    InputText,
    InputNumber,
    PrimeDialog,
  },
  data() {
    return {
      isLoading: false,
      tableData: [],
      showModel: false,
      imageUrl: "",
      base64String: "",
      modelHeader: "",
      failureProbability: 0,
      confirmObj: {
        message: "",
        isOpen: false,
        assetId: "",
        assetName: "",
      },
      dispose: {
        assetId: undefined,
        isOpen: false,
        assetName: undefined,
      },
      newAsset: {
          isOpen: false
      },
      filterFields: [
        "role",
        "phase",
        "name",
        "responsible",
        "status",
        "createdAt",
      ],
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        companyId: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        assetType: { value: null, matchMode: FilterMatchMode.CONTAINS },
        lifetimeSpan: {
          value: null,
          matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
        },
        lifetimeLeft: {
          value: null,
          matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
        },
        lifetimeEstimated: {
          value: null,
          matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
        },
        operationalTime: {
          value: null,
          matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
        },
        failureProbability: {
          value: null,
          matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
        },
      },
      //statuses: ['Started', 'Stopped']
      statuses: [
        { text: "Started", value: 1 },
        { text: "Stopped", value: 0 },
      ],
    };
  },
  created() {
    console.log("Asset Manager: Created");
    this.$store.dispatch("parseHash").then(() => {
      this.fnLoad();
    });
        },
        mounted() {
            this.$logService.save(this.$options.name);
        },
  unmounted() {
    console.log("Unmounting asset Manager Page...");
  },
  methods: {
    async fnOpenGraph(asset) {
      console.log("Open chart for Asset Type: ");
      console.log(asset);
      const data = {
        simulator: "Survival_analysis",
        simulatorName: "Survival_analysis",
        inputs: {},
        params: {},
        schedule: {
          type: "click",
        },
      };

      data["inputs"] = {
        id: asset.companyId,
        type: asset.type,
        temperature: 35,
        humidity: 70,
        operational_hours: asset.operationalTime,
      };

      try {
        const result = await axios.post(
          `${this.$apiBase}/stellio/simulate`,
          data,
          {
            headers: { "Content-Type": "application/json" },
          }
        );
        console.log(result);
        if (result.data.failure_probability) {
          this.failureProbability = result.data.failure_probability;
          this.base64String = result.data.image;
          this.convertBase64ToImage();
          this.showModel = true;
        }
      } catch (error) {
        console.log("Error: " + error); 
      }

      // this.modelHeader = this.fnCapText(assetType) + " forecast";
      // this.showModel = true;
    },
    convertBase64ToImage() {
      const dataURIprefix = "data:image/";
      let dataURI = this.base64String;

      if (!this.base64String.startsWith(dataURIprefix)) {
        const mimeType = "image/png";
        dataURI = `${dataURIprefix}${mimeType};base64,${this.base64String}`;
      }

      this.imageUrl = dataURI;
    },
    fnLoad: function () {
      this.isLoading = true;
      this.tableData = [];
      //TODO: Call
      assetService
        .active(this.$store.getters.project)
        .then((data) => {
          //console.log(data);
          this.tableData = data;

          // template_data = {
          //     "simulator": "Survival_analysis",
          //     "simulatorName": "Survival_analysis",
          //     "inputs": {
          //         "id": "154ER",
          //         "operational_hours": 150,
          //         "temperature": 35,
          //         "humidity": 70
          //     },
          //     "params": {
          //     },
          //     "schedule": {
          //         "type" : "click"
          //     }
          // }

          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
          this.fnShowToast(
            "Error",
            "Error when loading assets...!!!",
            ToastSeverity.ERROR
          );
        });
      return;
    },
    fnOnSave(assetNew) {
      this.fnShowToast(
        "Save Success",
        'The asset "' + assetNew.name + '" was updated!',
        ToastSeverity.SUCCESS
      );
      //asset("Update asset: " + assetId);
      const currentIndex = this.tableData.findIndex(
        (p) => p.assetId === assetNew.assetId
      );

      this.tableData.splice(currentIndex, 1, assetNew);
    },
    fnOnDelete(assetId, assetName) {
      this.fnShowToast(
        "Delete Success",
        'The asset "' + assetName + '" was deleted!',
        ToastSeverity.WARN
      );
      //console.log('warning - The asset "' + assetName + '" was deleted!');

      const currentIndex = this.tableData.findIndex(
        (p) => p.assetId === assetId
      );
      this.tableData.splice(currentIndex, 1);
    },
    fnDelete() {
      this.loading = true;

      assetService
        .delete(this.confirmObj.assetId)
        .then(() => {
          //.then((data) => {
          //console.log(data);
          this.fnOnDelete(this.confirmObj.assetId, this.confirmObj.assetName);

          this.confirmObj.message = "";
          this.confirmObj.isOpen = false;
          this.confirmObj.assetId = "";
          this.confirmObj.assetName = "";

          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.fnShowToast(
            "Error",
            'An error occurred when trying to delete the asset "' +
              this.confirmObj.assetName +
              '"!!!',
            ToastSeverity.ERROR
          );
          this.loading = false;
        });
    },
    fnShowToast(title, message, severity) {
      this.$toast.add({
        severity: severity,
        summary: title,
        detail: message,
        life: 6000,
      });
    },
    fnShowMessage(title, message, severity) {
      this.$toast.add({
        severity: severity,
        summary: title,
        detail: message,
        life: 30000,
      });
    },
    fnOpenConfBox: function (assetId, assetName) {
      this.confirmObj.assetId = assetId;
      this.confirmObj.assetName = assetName;
      this.confirmObj.message =
        "Do you really want to delete the asset: '" + assetName + "'?";
      this.confirmObj.isOpen = true;
    },
    fnCloseConfBox() {
      this.confirmObj.assetId = "";
      this.confirmObj.assetName = "";
      this.confirmObj.message = "";
      this.confirmObj.isOpen = false;
    },
    fnAddNew() {
      this.newAsset.isOpen = true;
    },
    fnNewAdded(asset){
        
        this.fnShowToast("Save Success", 'The asset "' + asset.name + '" was inserted!', ToastSeverity.SUCCESS);
        //asset("Update asset: " + assetId);
        this.tableData.push(asset);
        this.newAsset.isOpen = false;
    },
    fnNewClose(){
        this.newAsset.isOpen = false
    },
    fnDetailsOpen(assetId, assetName) {
      this.dispose.assetId = assetId;
      this.dispose.assetName = assetName;
      this.dispose.isOpen = true;
    },
    fnDetailsClose() {
      this.dispose.assetId = undefined;
      this.dispose.assetName = undefined;
      this.dispose.isOpen = false;
    },
    fnDisposeAsset(dispose) {
      //this.fnShowToast('Dispose Success', 'The asset "' + assetName + '" was disposed!', ToastSeverity.WARN);
      const currentIndex = this.tableData.findIndex(
        (p) => p.assetId === dispose.assetId
      );

      this.tableData.splice(currentIndex, 1);
    },
    fnWorkAdded(workAdded) {
      const currentIndex = this.tableData.findIndex(
        (p) => p.assetId === workAdded.assetId
      );
      if (currentIndex >= 0) {
        this.tableData[currentIndex].operationalTime += workAdded.workHours;
        this.tableData[currentIndex].lifetimeLeft -= workAdded.workHours;
        this.tableData[currentIndex].lifetimeEstimated -= workAdded.workHours;
      }
      //alert("The alert was disposed");
    },
    fnWorkRemoved(workRemoved) {
      const currentIndex = this.tableData.findIndex(
        (p) => p.assetId === workRemoved.assetId
      );
      if (currentIndex >= 0) {
        this.tableData[currentIndex].operationalTime -= workRemoved.workHours;
        this.tableData[currentIndex].lifetimeLeft += workRemoved.workHours;
        this.tableData[currentIndex].lifetimeEstimated += workRemoved.workHours;
      }
      //alert("The alert was disposed");
    },
    fnFormatDate(dateValue) {
      let result = new Date(2000, 0, 1);
      if (dateValue != undefined && dateValue instanceof Date) {
        //console.log(dateValue.substring(0, 10));
        result = dateValue;
      }

      return result.toLocaleString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });
      //return result.toLocaleString('en-GB', { dateStyle: "short" });
    },
    fnCapText(text) {
      return capitalizeFirstLetter(text);
    },
    fnFormatNumber(number) {
      return formatNumber(number);
    },
    fnFormatPercentage(number) {
      return formatPercentage(number);
    },
  },
};
</script>
