<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="custom-rich-data-table">
    <div class="border-2 p-2 shadow-md table-widget rounded-md border-indigo-400">
      <h3
        class="text-indigo-600 text-center text-xl dark:text-indigo-300"
        style="text-transform: uppercase; font-weight: bold"
      >
        {{ chart["name"] }}
      </h3>
      <div class="text-xs dark:text-surface-100">Click on each row to see details.</div>
      <DataTable
        :value="tableRows"
        tableStyle="min-width: 35rem; font-size: 14px;"
        :rows="5"
        v-model:filters="filters"
        filterDisplay="row"
        @rowSelect="onRowSelect"
        selectionMode="single"
        class="bg-indigo-200 dark:text-surface-100"
        :loading="loading"
        lazy
        paginator
        :totalRecords="totalRecords"
        @page="onPage($event)"
        @filter="onFilter($event)"
        @sort="onSort($event)"
        sortMode="single">
        <template #empty>
            <span class="font-bold">No records found.</span>
        </template>
        <Column
          v-for="(column, colIdx) in columns"
          :key="colIdx"
          :field="column"
          :header="column"
          sortable
        >
          <template #body="{ data }">
            <span v-if="column in conf.colorBlockCols">
              <Tag :value="data[column]" :severity="getColorBlock(column, data[column])" />
            </span>
            <span v-else-if="column in conf.ratingCols">
              <span
                v-for="starIndex in getTotalRatingStars(column)"
                :key="starIndex"
                class="star"
                :class="{ filled: starIndex <= Math.round(data[column]) }"
                >{{
                  starIndex <= Math.round(data[column]) ? "&#9733;" : "&#9734;"
                }}</span
              >
            </span>
            <span v-else-if="typeof data[column] === 'boolean'">
              <i
                class="pi"
                :class="{
                  'pi-check-circle text-green-500': data[column],
                  'pi-times-circle text-red-400': !data[column],
                }"
              ></i>
            </span>
            <span v-else>{{ data[column] }}</span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              @keydown.enter="filterCallback()"
              class="p-column-filter"
              placeholder="Search"
            />
          </template>
        </Column>
      </DataTable>
    </div>

    <div>
      <PrimeDialog
        v-model:visible="showModel"
        maximizable
        modal
        header="View"
        :style="{ width: '50rem' }"
        :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
      >
        <table v-if="!edit">
          <tbody>
            <tr
              v-for="(value, key) in selectedRow"
              :key="key"
              class="m-5 p-5 row"
            >
                <td class="font-bold key">{{ fnCapText(key) }}</td>
              <td>:</td>
              <td class="m-2 p-3 block bg-violet-100 dark:text-slate-600">
                  <pre v-if="key == 'message'">{{ value }}</pre>
                  <div v-else v-html="fnParseValue(value)" />
              </td>
            </tr>
          </tbody>
        </table>
        <table v-else>
            <tbody>
                <tr v-for="item in conf.updateCols"
                    :key="item.column"
                    class="m-5 p-5 row">
                    <td class="font-bold key">{{ fnCapText(item.column) }}</td>
                    <td>:</td>
                    <td class="m-2 value w-full">
                        <InputText v-if="item.formInput == 'text'"
                                   v-model="editedRow[item.column]"
                                   class="bg-indigo-100 w-full edit-input dark:text-slate-600" />

                        <Dropdown v-if="item.formInput == 'dropdown'"
                                  v-model="editedRow[item.column]" :options="item.valueList" optionLabel="text" optionValue="value"
                                  placeholder="Please Select one" class="w-full" inputClass="edit-input bg-indigo-100 dark:text-slate-600" />

                        <InputNumber v-if="item.formInput == 'number'"
                                     v-model="editedRow[item.column]" inputClass="w-full edit-input bg-indigo-100 dark:text-slate-600" />
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="flex justify-end mt-4">
          <div v-if="!edit" @click="editDoc" class="cursor-pointer">
            <span>
              <i class="pi pi-file-edit" style="color: slateblue"></i>
            </span>
          </div>
          <div>
              <PrimeButton v-if="edit"
                           @click="updateCancel"
                           label="Cancel"
                           severity="secondary"
                           icon="pi pi-arrow-circle-left" iconPos="left" 
                           class="mr-2" />
              <PrimeButton v-if="edit"
                           @click="updateDoc"
                           label="Update" title="Save changes."
                           icon="pi pi-check-circle" iconPos="right" />
              
          </div>
        </div>
      </PrimeDialog>
    </div>
  </div>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import InputNumber from "primevue/inputnumber";
import PrimeDialog from "primevue/dialog";
import axios from "axios";
import PrimeButton from "primevue/button";
import { FilterMatchMode } from "primevue/api";
import Tag from "primevue/tag";
import { ref } from "vue";

import { parseJsonValue } from "@/utils/ParseJson.js";
import { capitalizeFirstLetter, arrayHasValue } from "@/utils/utils.js";

export default {
  components: {
    DataTable, Column,
    InputText, Dropdown, InputNumber,
    PrimeDialog,
    PrimeButton,
    Tag,
  },
  props: {
    chartObject: Object,
    url: Object,
    clickEvent: Object
  },

  data() {
    return {
      columns: [],
      title: "",
      tableRows: [],
      filters: {},
      sorting: undefined,
      showModel: false,
      selectedRow: {},
      edit: false,
      editedRow: {},
      rowSchema: {},
      first: 0,
      totalRecords: 0,
      loading: false,
      schema: {},
      chart: {},
      conf: {},
    };
  },
    watch: {
        chartObject: {
            immediate: true,
            handler(newValue) {
                //console.log("table Chart:", newValue);
                this.chart = newValue;
                console.log("_-------_ URL");
                //console.log(this.url);
                this.loadData(
                    {
                        first: 0,
                        rows: 5,
                    },
                    newValue,
                    {}
                );
            }
        },
        clickEvent: function (val) {
            //console.log("CustomRichDataTableComponent - clickEvent", val, this.filters);
            if (val != undefined && val.type == "filtering") {
                var keys = Object.keys(this.filters);
                if (keys.length > 0) {
                    keys.forEach((key) => {
                        this.filters[key].value = undefined;
                    });
                }
                this.filters[val.key] = { value: val.value, matchMode: FilterMatchMode.EQUALS };
                //console.log("filters", this.filters);
                this.loadData(
                    this.lazyParams,
                    this.chart,
                    this.filters
                );
            }
        }
    },
  methods: {
    onRowSelect(event) {
      //console.log(event.data);
      this.selectedRow = event.data;
      this.editedRow = event.data;
      this.edit = false;
      let getUrl = `${this.$apiBase}/${this.url.onRowSelect}/${this.chart.configuration}/${this.selectedRow._id}`
      //console.log("getUrl", getUrl);
      axios
        .get(getUrl)
        .then((response) => {
          console.log("----- ROW -----");
          //console.log(response.data);
          this.selectedRow = response.data.document;
          this.editedRow = response.data.document;
          this.rowSchema = response.data.schema;
          this.showModel = true;
          //return {"schema": schema, "document": document}
        })
        .catch((error) => {
          console.error("Error loading data:", error);
        });
    },
      async updateDoc() {
          try {
              for (let key in this.editedRow) {
                  this.selectedRow[key] = this.editedRow[key];
              }

              for (let key in this.rowSchema) {
                  //console.log(key);
                  //console.log(typeof this.schema[key]);
                  if (
                      this.rowSchema[key] === "number" ||
                      this.rowSchema[key] === "float" ||
                      this.rowSchema[key] === "int"
                  ) {
                      this.selectedRow[key] = parseFloat(this.selectedRow[key]);
                  } else if (this.rowSchema[key] === "bool") {
                      //console.log(this.selectedRow[key] === "true");
                      this.selectedRow[key] = this.selectedRow[key] === "true";
                  }
                  //else if (this.rowSchema[key] === 'datetime') {
                      //console.log(this.selectedRow[key]);
                      //this.selectedRow[key] = new Date(this.selectedRow[key]);
                      //console.log(this.selectedRow[key]);
                  //}
                  else if (this.rowSchema[key] === "str"){
                      this.selectedRow[key] = this.selectedRow[key].trim();
                  }
                  // else if (this.schema[key] === 'object') {
                  //   console.log(JSON.parse(this.editedRow[key]))
                  //   this.editedRow[key] = JSON.parse(this.editedRow[key])
                  // }
              }
          } catch (error) {
              console.log(error);
              return;
          }

          console.log(this.selectedRow);

          try {
              const response = await axios.put(
                  `${this.$apiBase}/${this.url.onUpdate}/${this.selectedRow._id}`,
                  { document: this.selectedRow, schema: this.rowSchema }
              );
              console.log(response);
              this.edit = false;
              // this.selectedRow = this.editedRow;
              this.showModel = false;
              this.loadData(
                  this.lazyParams,
                  this.chart,
                  this.filters
              );
              this.$emit("recordUpdated")
          } catch (error) {
              console.error("Error:", error);
          }
      },
    editDoc() {
      this.edit = true;
      console.log(this.rowSchema);
      this.editedRow = {};
      /*if (typeof this.conf.updateCols === "string") {
        this.conf.updateCols = this.conf.updateCols
          .split(",")
          .map((item) => item.trim());
      }*/
      this.conf.updateCols.forEach((col) => {
          this.editedRow[col.column] = this.selectedRow[col.column];
      });
    },
    updateCancel(){
        this.edit = false;
        this.editedRow = {};
    },
    onPage(event) {
        console.log("+++++ PAGING EVENT TRIGGERED +++++");
        //console.log(event);
        this.lazyParams = event;
        this.loadLazyData(event);
    },
    onFilter(event) {
        console.log("+++++ FILTER EVENT TRIGGERED +++++");
        //console.log("filters", this.filters);
        this.lazyParams = event;
        this.loadLazyData(event);
    },
    onSort(event) {
        console.log("+++++ SORTING EVENT TRIGGERED +++++");
        //console.log(event);
        this.lazyParams = event;
      this.loadLazyData(event);
    },
    loadLazyData(event) {
      this.lazyParams = {
        ...this.lazyParams,
        first: event?.first || this.first,
      };
      console.log("++++++++++ LOAD LAZY DATA ++++++++++++");
      //console.log(event, this.chart);
      this.loadData(event, this.chart, event.filters);
    },
    loadData(event, chart, filters) {
      this.loading = true;
        const currentPage = event && event.page != undefined ? event.page + 1 : 1;
        const pageSize = event != undefined && event.rows != undefined ? event.rows : 10;

      const queryParams = {
        page: currentPage,
        page_size: pageSize,
        sort_field: event ? event.sortField : "_id",
        sort_order: event ? event.sortOrder : 1,
        filters: JSON.stringify(filters),
      };

      console.log("-------- URL");
      //console.log(queryParams);

      axios
        .get(`${this.$apiBase}/${this.url.onLoad}`, {
          params: queryParams,
        })
        .then((response) => {
          //console.log(response.data);
          const records = response.data.logs;

          this.tableRows = records;  
          this.totalRecords = response.data.total_documents;
          this.schema = response.data.schema;
          this.conf = response.data.conf;
          this.chart = chart;

          if (!this.conf.ratingCols) {
            this.conf.ratingCols = {};
          }

          if (!this.conf.colorBlockCols) {
            this.conf.colorBlockCols = {};
          }
          
          if (arrayHasValue(records)) {
            this.columns = Object.keys(records[0]);
            this.columns = this.columns.filter((item) => item !== "_id");
            
            Object.keys(records[0]).forEach((key) => {
            this.filters[key] = {
                value: response.data.filters[key]?.value || null,
                matchMode:
                response.data.filters[key]?.matchMode ||
                FilterMatchMode.CONTAINS,
              };
            });
          }
          this.loading = false;
        })
        .catch((error) => {
          console.error("Error loading data:", error);
          this.logDataLoading = false;
        });
    },
    getColorBlock(col, val) {
      return this.conf.colorBlockCols[col] && this.conf.colorBlockCols[col][val]
        ? this.conf.colorBlockCols[col][val]
        : this.conf.colorBlockCols[col]["default-color"];
    },
    getTotalRatingStars(col) {
      return this.conf.ratingCols[col]["totalStars"]
        ? this.conf.ratingCols[col]["totalStars"]
        : 5;
    },
    getRating(value) {
      return ref(value);
      },
      fnParseValue(text) {
          return parseJsonValue(text);
      },
      fnCapText(text) {
          return capitalizeFirstLetter(text);
      },
  },
};
</script>

<style scoped>
.star {
  color: gray; /* Adjust star color as needed */
  font-size: 24px; /* Adjust star size as needed */
}

.filled {
  color: green; /* Adjust filled star color as needed */
}
</style>
