<template>
    <div class="flex align-middle px-5">
        <h2 class="text-4xl leading-tight text-center m-auto text-bold">Robots Information</h2>
        <!--<Button type="button" label="ASSET" icon="pi pi-plus-circle" iconPos="right"
            class="ml-auto" title="Add a new Asset" @click="fnAddNew"></Button>-->
    </div>
    <div>
        <dynamic-widget-component tab="robots"></dynamic-widget-component>
    </div>
    <div class="px-5 py-3 flex-auto">
        <div class="border-2 p-2 shadow-md table-widget rounded-md border-indigo-400">
            <h3 class="text-indigo-600 text-center text-xl"
                style="text-transform: uppercase; font-weight: bold">
                ROBOTS
            </h3>
            <DataTable :value="tableData" stripedRows class="w-full" size="small"
                       paginator :rows="10" :rowsPerPageOptions="[10, 20, 30, 40, 50, 100]"
                       scrollable sortField="createdAt" :sortOrder="-1"
                       v-model:filters="filters" filterDisplay="row" :loading="isLoading"
                       :globalFilterFields="filterFields">
                <template #empty>
                    No robots found.
                </template>
                <template #loading>
                    Loading robots data. Please wait...
                </template>
                <Column :exportable="false" bodyClass="dark:text-surface-200">
                    <template #body>
                        <Button icon="pi pi-chart-bar" outlined rounded size="small" title="Details"
                                class="dark:text-primary-300 dark:border-primary-300"
                                @click="fnOpenGraph('Robot')" />
                    </template>
                </Column>
                <Column :exportable="false" bodyClass="dark:text-surface-200">
                    <template #body="slotProps">
                        <Button icon="pi pi-search" outlined rounded size="small" title="Dispose"
                                class="dark:text-red-300 dark:border-red-300"
                                @click="fnDetailsOpen(slotProps.data.robotId, slotProps.data.name)" />
                    </template>
                </Column>
                <Column field="companyId" header="Id" sortable bodyClass="dark:text-surface-200">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" type="text" @input="filterCallback" class="w-32" placeholder="Search by Id" size="small" />
                    </template>
                </Column>
                <Column field="name" header="Asset" sortable bodyClass="dark:text-surface-200">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" type="text" @input="filterCallback" class="w-[16rem]" placeholder="Search by name" size="small" />
                    </template>
                </Column>
                <Column field="disposed" header="Active" sortable :showFilterMenu="false" bodyClass="dark:text-surface-200">
                    <template #body="{ data }">
                        <Tag :value="data.disposed ? 'Disposed' : 'Active'" :severity="data.disposed ? 'danger' : 'success'" />
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <Dropdown v-model="filterModel.value" @change="filterCallback" :options="statuses" optionLabel="text" optionValue="value"
                                  placeholder="Select" :showClear="false" inputClass="p-2 w-[6rem]">
                            <template #option="slotProps">
                                <Tag :value="slotProps.option.text" :severity="slotProps.option.value == 'true' ? 'danger' : 'success'" />
                            </template>
                        </Dropdown>
                    </template>
                </Column>
                <Column field="lifetimeSpan" header="Maximum (Hr)" dataType="numeric" sortable bodyClass="dark:text-surface-200">
                    <template #body="{ data }">
                        {{ fnFormatNumber(data.lifetimeSpan) }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputNumber v-model="filterModel.value" @input="filterCallback" inputClass="w-[6rem] p-2" size="small" />
                    </template>
                </Column>
                <Column field="operationalTime" header="Operational (Hr)" dataType="numeric" sortable bodyClass="dark:text-surface-200">
                    <template #body="{ data }">
                        {{ fnFormatNumber(data.operationalTime) }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputNumber v-model="filterModel.value" @input="filterCallback" inputClass="w-[6rem] p-2" size="small" />
                    </template>
                </Column>
                <Column field="lifetimeLeft" header="Remaining (hr)" dataType="numeric" sortable bodyClass="dark:text-surface-200">
                    <template #body="{ data }">
                        {{ fnFormatNumber(data.lifetimeLeft) }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputNumber v-model="filterModel.value" @input="filterCallback" inputClass="w-[6rem] p-2" size="small" />
                    </template>
                </Column>
                <Column field="lifetimeEstimated" header="Predicted (hr)" dataType="numeric" sortable bodyClass="dark:text-surface-200">
                    <template #body="{ data }">
                        {{ fnFormatNumber(data.lifetimeEstimated) }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputNumber v-model="filterModel.value" @input="filterCallback" inputClass="w-[6rem] p-2" size="small" />
                    </template>
                </Column>
                <Column field="failureProbability" header="Failure (%)" dataType="numeric" sortable bodyClass="dark:text-surface-200">
                    <template #body="{ data }">
                        {{ fnFormatPercentage(data.failureProbability/100) }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputNumber v-model="filterModel.value" @input="filterCallback" inputClass="w-[6rem] p-2" size="small" :maxFractionDigits="2" suffix="%" />
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>
    <LoaderComponent :isLoading="isLoading" type="primary"></LoaderComponent>
    <PrimeDialog v-model:visible="showModel" modal
                 v-model:header="modelHeader"
                 :style="{ width: '50rem' }"
                 :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
        <img width="600" src="@/assets/asets-analytics.png" alt="Robot Type Forecast" />
    </PrimeDialog>
    <robot-details-component :entityId="details.entityId" :entityName="details.entityName" :isOpen="details.isOpen" @onWorkAdded="fnWorkAdded"
                             @onError="fnShowToast" @onDispose="fnDispose" @onClose="fnDetailsClose">
    </robot-details-component>
</template>

<script>
    import DynamicWidgetComponent from "@/components/DynamicWidgetComponent";
    import LoaderComponent from "@/components/LoaderComponent.vue";
    import RobotDetailsComponent from '@/components/Robots/RobotDetailsComponent'
    
    import Button from 'primevue/button';
    import DataTable from 'primevue/datatable';
    import Column from 'primevue/column';
    import InputText from 'primevue/inputtext';
    import InputNumber from 'primevue/inputnumber';
    import PrimeDialog from "primevue/dialog";
    import Tag from "primevue/tag";
    import Dropdown from "primevue/dropdown";

    import { ToastSeverity } from 'primevue/api';
    import { FilterMatchMode } from 'primevue/api';

    import { robotService } from "@/services/RobotService.js";
    import { capitalizeFirstLetter, formatNumber, formatPercentage } from "@/utils/utils.js";


    export default {
        name: "RobotsPage",
        components: { 
            DynamicWidgetComponent, 
            DataTable, Column, Button,
            InputText, InputNumber,
            PrimeDialog,
            LoaderComponent,
            RobotDetailsComponent,
            Tag, Dropdown
        },
        data() {
            return {
                isLoading: false,
                tableData: [],
                filterFields: ['companyId', 'robotType', 'name', 'disposed'],
                filters: {
                    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    companyId: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    robotType: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    lifetimeSpan: { value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
                    lifetimeLeft: { value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
                    lifetimeEstimated: { value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
                    operationalTime: { value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
                    failureProbability: { value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
                    disposed: { value: null, matchMode: FilterMatchMode.EQUAL_TO }
                },
                modelHeader: "",
                showModel: false,
                details: {
                    entityId: undefined,
                    entityName: undefined,
                    isOpen: false
                },
                statuses: [{ text: 'Disposed', value: "true" }, { text: 'Active', value: "false" }]
            }
        },
        created() {
            console.log("Robot Manager: Created");
            this.fnLoad();
        },
        mounted() {
            this.$logService.save(this.$options.name);
        },
        methods: {
            fnLoad: function () {
                this.isLoading = true;
                this.tableData = [];
                
                robotService.find(this.$store.getters.project)
                    .then((data) => {
                        //console.log(data);
                        data.forEach((item) => { item.failureProbability *= 100; }); 

                        this.tableData = data;

                        this.isLoading = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.isLoading = false;
                        this.fnShowToast('Error', 'Error when loading robots...!!!', ToastSeverity.ERROR);
                    });
                return;
            },
            fnOpenGraph(assetType){
                console.log("Open chart for Asset Type: " + assetType);
                this.modelHeader = this.fnCapText(assetType) + " forecast";
                this.showModel = true;
            },
            fnDetailsOpen(id, name) {
                this.details.entityId = id;
                this.details.entityName = name;
                this.details.isOpen = true;
            },
            fnDetailsClose() {
                this.details.entityId = undefined;
                this.details.entityName = undefined;
                this.details.isOpen = false;
            },
            fnDispose(disposed) {
                //this.fnShowToast('Dispose Success', 'The asset "' + assetName + '" was disposed!', ToastSeverity.WARN);
                const currentIndex = this.tableData.findIndex(p => p.robotId == disposed.entityId);
                this.tableData[currentIndex].disposed = true;
                this.tableData[currentIndex].dispose = disposed;
                //this.tableData.splice(currentIndex, 1);
            },
            fnWorkAdded(workAdded){
                const currentIndex = this.tableData.findIndex(p => p.robotId === workAdded.robotId);
                if (currentIndex >= 0)
                {
                    this.tableData[currentIndex].operationalTime += workAdded.workHours;
                    this.tableData[currentIndex].lifetimeLeft -= workAdded.workHours;
                    this.tableData[currentIndex].lifetimeEstimated -= workAdded.workHours;
                }
            },
            fnShowToast(title, message, severity) {
                this.$toast.add({ severity: severity, summary: title, detail: message, life: 6000 });
            },
            fnShowMessage(title, message, severity) {
                this.$toast.add({ severity: severity, summary: title, detail: message, life: 30000 });
            },
            fnCapText(text){
                return capitalizeFirstLetter(text);
            },
            fnFormatNumber(number){
                return formatNumber(number);
            },
            fnFormatPercentage(number) {
                return formatPercentage(number);    
            }
        }
    }
</script>
