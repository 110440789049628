import { axiosService } from "@/services/AxiosService.js";
class StellioService {

    constructor() {
      this.entityPath = "stellio";
    }

  //save(alertObj) {
  //  let actionPath = `${this.entityPath}/save`;
  //  console.log(`Post to: ${actionPath}`);

  //  return axiosService.doPost(actionPath, alertObj);
  //}

  get(entityId) {
    let actionPath = `${this.entityPath}/entity/${entityId}`;
    console.log(`Get to: ${actionPath}`);

    return axiosService.doGet(actionPath);
  }

  list(projectId, stellioType) {
    let actionUrl = `${this.entityPath}/entities/${stellioType}/${projectId}`
    console.log(`Get to: ${actionUrl}`);

    return axiosService.doGet(actionUrl);
  }

  //find(project) {
  //  let actionPath = `${this.entityPath}/find/${project}`;
  //  console.log(`Get to: ${actionPath}`);

  //  return axiosService.doGet(actionPath);
  //}

  //delete(alertId) {
  //  const actionPath = `${this.entityPath}/del/${alertId}`;
  //  console.log(`Delete to: ${actionPath}`);

  //  return axiosService.doDelete(actionPath);
  //}

}

export const stellioService = new StellioService();
