<template>
    <div id="app">
        <div v-if="$store.getters.isAuthenticated">
            <div v-if="showMenu"
                 class="flex items-center justify-between flex-wrap shadow-[0_2px_6px_-1px_rgba(0,0,0,0.1)] p-3 sticky top-0 bg-white z-40">
                <div class="flex items-center flex-shrink-0 text-dark mr-6">
                    <img class="h-16 w-72"
                         src="@/assets/robetarme_final_logo-2.png"
                         alt="RobetArme logo." />
                </div>
                <div class="block lg:hidden">
                    <button @click="toggleMenu()"
                            class="flex items-center px-3 py-2 border rounded text-indigo-200 border-indigo-400">
                        <svg class="fill-current h-3 w-3"
                             viewBox="0 0 20 20"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                        </svg>
                    </button>
                </div>
                <transition name="nav-group">
                    <div v-show="showMenu"
                         class="w-full block flex-grow lg:flex lg:items-center lg:w-auto lg:show">
                        <div class="lg:flex lg:flex-grow lg:items-center">
                            <NavButtonComponentVue v-if="showOptions" name="Information"
                                                   @navClick="fnNavigatePage('project-info')"
                                                   :show="selectedMenu.indexOf('project-info') >= 0"></NavButtonComponentVue>
                            <NavButtonComponentVue v-if="showOptions && !hmiUser" name="Alerts"
                                                   @navClick="fnNavigatePage('project-alerts')"
                                                   :show="selectedMenu.indexOf('project-alerts') >= 0"></NavButtonComponentVue>
                            <NavButtonComponentVue v-if="showOptions" name="Anomalies"
                                                   @navClick="fnNavigatePage('anomaly-detection')"
                                                   :show="selectedMenu.indexOf('anomaly-detection') >= 0"></NavButtonComponentVue>
                            <NavButtonComponentVue v-if="showOptions" name="Assets"
                                                   @navClick="fnNavigatePage('assets-management')"
                                                   :show="selectedMenu.indexOf('assets-management') >= 0"></NavButtonComponentVue>
                            <NavButtonComponentVue v-if="showOptions" name="Robots"
                                                   @navClick="fnNavigatePage('robots')"
                                                   :show="selectedMenu.indexOf('robots') >= 0"></NavButtonComponentVue>
                            <NavButtonComponentVue v-if="showOptions" name="Materials"
                                                   @navClick="fnNavigatePage('materials')"
                                                   :show="selectedMenu.indexOf('materials') >= 0"></NavButtonComponentVue>
                            <NavButtonComponentVue v-if="showOptions" name="Concrete Batch"
                                                   @navClick="fnNavigatePage('batch-management')"
                                                   :show="selectedMenu.indexOf('batch-management') >= 0"></NavButtonComponentVue>
                            <NavButtonComponentVue v-if="showOptions" name="Incidents"
                                                   @navClick="fnNavigatePage('incident-management')"
                                                   :show="selectedMenu.indexOf('incident-management') >= 0"></NavButtonComponentVue>
                            <!--<NavButtonComponentVue v-if="showOptions" name="Structural Integrity"
                    @navClick="fnNavigatePage('structural-integrity')"
                    :show="selectedMenu.indexOf('structural-integrity') >= 0"></NavButtonComponentVue>-->
                            <NavButtonComponentVue v-if="showOptions" name="Management"
                                                   @navClick="toggleManage"
                                                   :show="selectedMenu.indexOf('manager') >= 0"></NavButtonComponentVue>
                            <Menu ref="menuMan"
                                  id="menuManage"
                                  :model="items"
                                  :popup="true" />
                        </div>
                        <div class="float-right">
                            <div v-if="this.$store.getters.project" @click="fnConfigureProject"
                                 class="font-mono hover:cursor-pointer inline-block mr-2 px-2 py-2 leading-none border rounded text-primary-500 border-primary-500 hover:bg-indigo-100 lg:mt-0 bg-white">
                                Configure: {{ this.activeProjectName }}
                            </div>
                        </div>
                        <div class="float-right">
                            <div @click="fnLogout"
                                 class="font-mono hover:cursor-pointer inline-block px-4 py-2 leading-none border rounded text-grey-700 border-dark hover:border-transparent hover:bg-indigo-100 mt-4 lg:mt-0 bg-white dark:text-slate-500 dark:border-dark">
                                Logout
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
            <div class="flex flex-wrap w-full my-4 h-fit">
                <div class="break-words bg-whie shadow-lg rounded text-gray-600 w-full pb-4">
                    <router-view></router-view>
                </div>
            </div>
            <footer-component />
        </div>
        <toast-component :type="toast.type"
                         :msg="toast.msg"
                         :show="toast.show"
                         @closeToast="fnCloseToast"></toast-component>
        <Toast></Toast>
        <ConfirmPopup></ConfirmPopup>
    </div>
</template>

<script>
import NavButtonComponentVue from "@/components/NavButtonComponent.vue";
import ToastComponent from "@/components/ToastComponent";
import FooterComponent from "@/components/FooterComponent";
    
import Menu from "primevue/menu";
import Toast from "primevue/toast";
import ConfirmPopup from 'primevue/confirmpopup';

import { userService } from "@/services/UserService.js";
import { catalogService } from "@/services/CatalogService.js";
import { stringHasValue } from "@/utils/utils.js";
    import Constants from "@/utils/Constants.js";

export default {
  name: "App",
  components: {
    NavButtonComponentVue,
    ToastComponent,
    Menu, Toast,
    ConfirmPopup,
    FooterComponent
  },
  data() {
    return {
      selectedMenu: "/",
      isConfirmationBoxOpen: false,
      showMenu: true,
      isAdmin: false,
      roles: [],
      toast: {
        type: "",
        msg: "",
        show: false,
      },
      projects: [],
      activeProjectId: "",
      activeProjectName: "Project",
        showOptions: true,
        hmiUser: false,
      isAuthenticated: false,
      items: [
        {
          label: "Alert Manager",
          command: () => {
            this.fnNavigatePage("alert-manager");
          },
        },
        {
          label: "Ingestion Manager",
          command: () => {
            this.fnNavigatePage("ingestion-manager");
          },
        },
        {
          label: "Change Project",
          command: () => {
            this.fnChangeProject();
          },
        }
      ],
      menuRoles: [],
    };
  },
  created() {
    this.$watch(
      () => this.$store.getters.isAuthenticated,
      (newValue) => {
        if (newValue) {
          this.initializeApp();
        }
      }
    );
    let uri = window.location.pathname;
    this.selectedMenu = uri;
    console.log(`App.vue - Mount - Is Authenticated: ${this.$store.getters.isAuthenticated}`);
    console.log(`App.vue - Mount - Selected Menu: ${this.selectedMenu}`);
  },
  unmounted() {
    if (this.connection && this.connection.readyState == 1) {
      this.connection.close();
    }
  },
  mounted() {
    console.log("App.vue - initSession");
    console.log("Active project - " + this.activeProjectId);
    this.showOptions = stringHasValue(this.activeProjectId);

    this.$emitter.on('changeProject', this.fnProjectSelected);
    this.$emitter.on('deleteProject', this.fnChangeProject);
    this.$emitter.on('navigatePage', this.fnNavigatePage);
    this.$emitter.on('hideMenu', this.fnHideMenu);

  },
  watch: {
    activeProjectId: function (val) {
        console.log("Change Active Project to: " + val);
        this.showOptions = stringHasValue(val);
    }
},
  methods: {
    initializeApp() {
      console.log("App.vue - Initializing Application");
      this.roles = this.$store.getters.roles;
      this.isAuthenticated = this.$store.getters.isAuthenticated;
      if (this.isAuthenticated) {
        this.fnLoadRoles();
        this.fnSetProject();

        this.activeProjectId = this.$store.getters.project;
        if (this.activeProjectId && this.activeProjectId.length > 0) {
          this.fnUpdateProject(this.activeProjectId);
        }

          this.fnConnectWebsocket();
          this.hmiUser = Array.isArray(this.roles) && this.roles.includes(Constants.Roles.HMI);
      }
    },
    fnMessage(event) {
      console.log("An event has arrived!!!");

      let dssAlert = JSON.parse(event.data);

      this.fnShowToast(dssAlert.text, "danger");
    },
    fnLogout() {
      this.$store.dispatch("doLogout");
      return;
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    hasAccess: function (role) {
      //return role !== undefined;
      //console.log(`role: ${role} - Has Access: ${this.roles.includes(role)}`);
      return (
        this.roles.includes(role.replace(/[^a-z0-9]/gi, "")) ||
        this.roles.includes("admin", "")
      );
    },
    fnCloseToast() {
      this.toast.show = false;
    },
    fnShowToast(msg, type) {
      this.toast.msg = msg;
      this.toast.type = type;
      this.toast.show = true;
    },
    fnLoggedIn() {
      console.log("Logged In...");

      this.$store.dispatch("setPermissions").then(() => {
        this.roles = this.$store.getters.roles;
        this.fnLoadRoles();
        this.fnSetProject();
      });
      this.isAuthenticated = true;
      this.fnConnectWebsocket();
    },
    toggleManage(event) {
      console.log("Toggle Management menu.");

      this.$refs.menuMan.toggle(event);
    },
    toggleRoles(event) {
      console.log("Toggle Role menu");

      this.$refs.menuRole.toggle(event);
    },
    fnLoadRoles() {
      this.menuRoles = [];
      console.log("Loading Roles...");
      // Load Roles.
      if (this.hasAccess("supervisor")) {
        this.menuRoles.push({
          label: "Supervisor",
          command: () => {
            this.fnNavigatePage("supervisor");
          },
        });
      }
      if (this.hasAccess("healthsafety")) {
        this.menuRoles.push({
          label: "Health & Safety",
          command: () => {
            this.fnNavigatePage("healthsafety");
          },
        });
      }
      if (this.hasAccess("procurement")) {
        this.menuRoles.push({
          label: "Procurement",
          command: () => {
            this.fnNavigatePage("procurement");
          },
        });
      }
      if (this.hasAccess("foreman")) {
        this.menuRoles.push({
          label: "Foreman",
          command: () => {
            this.fnNavigatePage("foreman");
          },
        });
      }
      if (this.hasAccess("operator")) {
        this.menuRoles.push({
          label: "Operator",
          command: () => {
            this.fnNavigatePage("operator");
          },
        });
      }
    },
    fnConnectWebsocket() {
      if (this.isAuthenticated && this.connection == undefined) {
        this.connection = new WebSocket(this.$alertWS);
        this.connection.onmessage = this.fnMessage;
        //this.connection.onopen = function (event) {
        this.connection.onopen = function () {
          //console.log(event);
          console.log("Successfully connected to the echo websocket server...");
        };
      }
    },
    fnConfigureProject() {
        this.selectedMenu = "project-configurator";
        this.$router.push({
        name: "project-configurator",
        params: { id: this.$store.getters.project },
      });
    },
    fnProjectSelected(project) {
        console.log('onChangeProject', 'projectId: ', project.id, 'projectName: ', project.name);

        this.activeProjectId = project.id;
        this.activeProjectName = project.name;
    },
    fnChangeProject() {
        this.activeProjectId = "";
        this.$store.commit("SET_PROJECT", "");
      this.fnNavigatePage("select-project");
    },
    fnUpdateProject(projectId) {
      console.log("Update project: " + projectId);
      this.projects = [];

      if (this.$store.getters.isAuthenticated) {
        catalogService
          .projects()
          .then((data) => {
            if (data != undefined && Array.isArray(data)) {
              this.projects = data;
            }
            this.activeProjectId = projectId;
          })
          .catch((error) => {
            console.log(error);
            this.fnShowToast(
              'Error when loading "projects" list...!!!',
              "danger"
            );
          });
      }
    },
    fnSetProject() {
      userService
        .get(this.$store.getters.username)
        .then((data) => {
          console.log("--------- SET PROJECT ---------");

          if (data != undefined && data.defaultProject) {
            let selectedProject = data.defaultProject;
            this.$store.commit("SET_PROJECT", selectedProject);
              let route = "project-info";

            console.log("route - " + route);
          } else {
            this.fnNavigatePage("select-project");
          }
        })
        .catch((error) => {
          console.log(error);
          this.fnShowAlertBox(
            "danger",
            "Error",
            "An error occurred when loading the User data!"
          );
        });
    },
    fnShowAlertBox(type, message) {
      this.toast.msg = message;
      this.toast.type = type;
      this.toast.show = true;
    },
    fnNavigatePage(route) {
      this.selectedMenu = route;
      this.$router.push({ name: route });
      },
      fnHideMenu() {
          this.showMenu = false;
      }
  },
};
</script>
<style scoped>
.nav-group-enter-active {
  transition: all 0.2s ease-in;
}

.nav-group-leave-active {
  transition: all 0.2s ease-out;
}

.nav-group-enter-from,
.nav-group-leave-to {
  transform: translateY(-40px);
}
</style>
