<template>
    <div class="px-10 flex-auto">
        <div class="flex px-2 flex-wrap h-fit lg:items-start lg:justify-start items-center justify-center mt-2">
            <div class="py-3">
                <img class="w-[64px] h-[43px]" src="../assets/255px-Flag_of_Europe.svg.png" id="union" />
            </div>
            <div>
                <p id="union-text" class="py-4 px-4 text-xs text-left">
                    This project has received funding from the European Union&#39;s Horizon Europe<br />
                    research and innovation programme under <b> Grant Agreement N&deg;XXXXXXXXX</b>.
                </p>
                <!--101058731-->
            </div>
        </div>
        <div class="flex px-2 flex-wrap h-fit lg:items-start lg:justify-start items-center justify-center mb-2">
            <span class="text-xs font-bold">RobetArme Decision Support System Version {{ version }}</span>
        </div>
    </div>
</template>
<script>
export default {
    name: "FooterComponent",
    data() {
      return {
          version: ""
      }
    },
    mounted() {
        console.log(process.env);
        this.version = this.$dssVersion; 
    }
};
</script>
