<template>
    <PrimeDialog v-model:visible="openModel" maximizable modal 
                 header="View"
                 :style="{ width: '50rem' }"
                 :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
                 @hide="fnClose">
        <div v-for="(value, key) in selectedRow" :key="key" class="m-5 p-5 row">
            <div class="font-bold key mb-4">
                <u>{{ fnCapText(key) }}</u>
            </div>
            <table>
                <tbody>
                    <tr v-for="(itemValue, itemKey) in value"
                        :key="itemKey"
                        class="m-5 p-5 row">
                        <td class="font-bold key">{{ itemKey }}</td>
                        <td>:</td>
                        <td class="m-2 p-3 block bg-violet-100 dark:text-slate-600">
                            <div v-html="fnParseValue(itemValue)" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </PrimeDialog>
</template>
<script>
import PrimeDialog from "primevue/dialog";

import { parseJsonValue } from "@/utils/ParseJson.js";
import { capitalizeFirstLetter } from "@/utils/utils.js";

export default {
    name: "DetailsTableComponent",
    emits: ["onClose"],
    components: {
        PrimeDialog
    },
    props: {
        selectedRow: Object,
        showModel: Boolean
    },
    data() {
        return { openModel: false};
    },
    watch: {
        showModel: function (val) {
            this.openModel = val;
            this.fnLog();
        }
    },
    methods: {
        fnParseValue(text) {
            return parseJsonValue(text);
        },
        fnCapText(text) {
            return capitalizeFirstLetter(text);
        },
        fnLog(){
            console.log("showModel: ", this.showModel);
            console.log("selectedRow: ", this.selectedRow);
        },
        fnClose(){
            this.$emit("onClose")
        }
    }
};
</script>
