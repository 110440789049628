<template>
    <div v-for="(product, pro_key) in data"
         :key="pro_key"
         class="flex-0 mb-4"
         style="margin-bottom: 64px">
        <div class="m-2 w-full mb-2">
            <span class="font-medium dark:text-surface-200">{{ pro_key }}</span>
        </div>
        <div class="bg-yellow-50 py-8 shadow-md mb-2"
             style="padding-top: 40px; padding-bottom: 40px">
            <div v-for="(sub_value, sub_key) in product" :key="sub_key">
                <div v-if="sub_key!='id'" class="mt-1 text-center font-semibold w-full mb-3 hover:cursor-pointer hover:underline" @click="onRowSelect(product)">
                    {{ sub_key }}
                </div>
                <div v-if="sub_key!='id'" class="flex flex-wrap mt-4 justify-center gap-4">
                    <div v-for="(value, key) in sub_value" :key="key">
                        <div class="mx-4 flex items-center">
                            <div class="flex items-center justify-center">
                                <Avatar icon="pi pi-wave-pulse"
                                        class="mr-2"
                                        shape="circle"
                                        style="background-color: #f8f7fc; color: #f82c7c" />
                            </div>
                            <div>
                                <div>
                                    <div class="m-0 text-center font-bold text-4xl">
                                        {{ value }}
                                    </div>
                                </div>
                                <div>
                                    <div class="text-base">{{ key }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <PrimeDialog v-model:visible="showModel"
                 maximizable
                 modal
                 header="View"
                 :style="{ width: '50rem' }"
                 :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
        <div v-for="(value, key) in selectedRow" :key="key" class="m-5 p-5 row">
            <div class="font-bold key mb-4">
                <u>{{ fnCapText(key) }}</u>
            </div>
            <table>
                <tbody>
                    <tr v-for="(itemValue, itemKey) in value"
                        :key="itemKey"
                        class="m-5 p-5 row">
                        <td class="font-bold key">{{ itemKey }}</td>
                        <td>:</td>
                        <td class="m-2 p-3 block bg-violet-100 dark:text-slate-600">
                            <div v-html="fnParseValue(itemValue)" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </PrimeDialog>
</template>

<script>
import axios from "axios";

import Avatar from "primevue/avatar";
import PrimeDialog from "primevue/dialog";

import { parseJsonValue } from "@/utils/ParseJson.js";
import { capitalizeFirstLetter } from "@/utils/utils.js";
    
export default {
  components: {
    PrimeDialog,
    Avatar,
  },
  emits: ["componentClick", "recordUpdated"],
  props: {
    chartObject: Object,
    url: Object,
    reload: Number,
    clickEvent: Object
  },
  data() {
    return {
      data: "",
      header: "",
      chart: "",
      dynamicColor: "white",
      conf: {},
      selectedRow: {},
      showModel: false
    };
  },
  watch: {
    chartObject: {
      immediate: true,
      handler(newValue) {
        console.log("--------- New Value");
        console.log(newValue);
        this.header = newValue.name;
        this.chart = newValue;
        this.getData();
      },
    },
  },
  methods: {
    getData() {
      console.log("--------- BIG CARD ----------");
      console.log(`${this.$apiBase}`);
      console.log(this.url);
      axios
        .get(`${this.$apiBase}/${this.url.onLoad}`)
        //.get(`${this.$apiBase}/${this.url}`)
        .then((response) => {
          console.log(response.data);
          this.data = response.data.data;
          this.conf = response.data.conf;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
    onRowSelect(data) {
      console.log(data);
      var getUrl = `${this.$apiBase}/${this.url.onRowSelect}/${this.chart.configuration}/${data.id}`;
      console.log(getUrl);
      axios
        .get(getUrl)
        .then((response) => {
            console.log("----- ROW -----");
            //console.log(response.data);
            this.selectedRow = response.data.data;
            if (Object.keys(this.selectedRow).length > 0) {
                this.showModel = true;
            }
            else
            {
                alert("No data found");
            }
        })
        .catch((error) => {
            console.error("Error loading data:", error);
        });
    },
    fnParseValue(text) {
        return parseJsonValue(text);
    },
    fnCapText(text) {
        return capitalizeFirstLetter(text);
    }
  }
};
</script>
