<template>
    <PrimeDialog v-model:visible="visible" modal header="Robot Details" @hide="fnClose"
                 :draggable="false" :style="{ width: '50rem' }"
                 :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
        <div v-if="!loading">
        <div class="w-full mb-2">
            <div class="w-full border-2 p-2 shadow-md table-widget rounded border-indigo-400">
                <div class="w-full flex flex-row">
                    <div class="m-0">
                        <div class="w-full flex flex-row">
                            <div class="w-32 text-indigo-800 dark:text-indigo-300">ID:</div>
                            <div>{{ entity.robotId }}</div>
                        </div>
                        <div class="w-full flex flex-row">
                            <div class="w-32 text-indigo-800 dark:text-indigo-300">Robot:</div>
                            <div>{{ entity.name }}</div>
                        </div>
                    </div>
                    <Button v-if="!entity.disposed" label="Dispose" title="Dispose Robot"
                            class="ml-auto max-h-10" severity="warning"
                            @click="fnDisposeOpen" rounded size="small" />
                </div>
            </div>
        </div>
        <div class="w-full mb-2">
            <div class="w-full border-2 p-2 shadow-md table-widget rounded border-indigo-400">
                <table class="table-auto w-full">
                    <thead>
                        <tr>
                            <th class="text-bold bg-surface-200 text-indigo-600 border border-surface-400 p-1 dark:bg-primary-400/30 dark:text-indigo-100" colspan="4">Hours</th>
                            <th class="text-bold bg-surface-200 text-indigo-600 border border-surface-400 p-1 dark:bg-primary-400/30 dark:text-indigo-100" rowspan="2">Failure <br />Probabilty</th>
                        </tr>
                        <tr>
                            <th class="text-bold bg-surface-200 text-indigo-600 border border-surface-400 p-1 dark:bg-primary-400/30 dark:text-indigo-100">Maximum</th>
                            <th class="text-bold bg-surface-200 text-indigo-600 border border-surface-400 p-1 dark:bg-primary-400/30 dark:text-indigo-100">Operational</th>
                            <th class="text-bold bg-surface-200 text-indigo-600 border border-surface-400 p-1 dark:bg-primary-400/30 dark:text-indigo-100">Remaining</th>
                            <th class="text-bold bg-surface-200 text-indigo-600 border border-surface-400 p-1 dark:bg-primary-400/30 dark:text-indigo-100">Predicted</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="border border-surface-400 p-1 text-center">{{ fnFormatNumber(entity.lifetimeSpan) }}</td>
                            <td class="border border-surface-400 p-1 text-center">{{ fnFormatNumber(entity.operationalTime) }}</td>
                            <td class="border border-surface-400 p-1 text-center">{{ fnFormatNumber(entity.lifetimeLeft) }}</td>
                            <td class="border border-surface-400 p-1 text-center">{{ fnFormatNumber(entity.lifetimeEstimated) }}</td>
                            <td class="border border-surface-400 p-1 text-center">{{ fnFormatPercentaje(entity.failureProbability) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="w-full mb-2" v-if="entity.disposed">
            <div class="w-full border-2 p-2 shadow-md table-widget rounded border-indigo-400">
                <div class="w-full flex flex-row">
                    <div class="w-32 text-indigo-800 dark:text-indigo-300">Disposed on:</div>
                    <div class="w-32">{{ fnFormatDate(entity.dispose.disposeDate) }}</div>
                    <div class="w-32 text-indigo-800 dark:text-indigo-300">Disposed type:</div>
                    <div class="w-32">{{ fnCapText(entity.dispose.disposeType) }}</div>
                </div>
                <div class="w-full flex flex-row">
                    <div class="w-32 text-indigo-800 dark:text-indigo-300">Reason:</div>
                    <div>{{ fnCapText(entity.dispose.reason) }}</div>
                </div>
            </div>
        </div>
        <div class="w-full mb-2 flex flex-row">
            <div class="w-full">
                <div class="border-2 p-2 shadow-md table-widget rounded-md border-indigo-400">
                    <div class="flex align-middle">
                        <h3 class="text-indigo-600 text-center m-auto text-bold"
                            style="text-transform: uppercase;">
                            Worked hours
                        </h3>
                        <Button icon="pi pi-plus" title="Add worked hours" class="ml-auto"
                                @click="fnWorkedTimeOpen" rounded size="small" />
                    </div>
                    <DataTable :value="entity.workLog" size="small" scrollHeight="18rem"
                               paginator :rows="4" :rowsPerPageOptions="[4, 8, 12, 25, 50]">
                        <Column field="workDate" header="Date">
                            <template #body="{ data }">
                                {{ fnFormatTextDate(data.workDate) }}
                            </template>
                        </Column>
                        <Column field="workHours" header="Worked hours"></Column>
                    </DataTable>
                </div>
            </div>
        </div>
        </div>
        <div v-if="loading">
            <loader-mini-component></loader-mini-component>
        </div>
        <template #footer>
            <Button label="Back" title="Close window" severity="secondary" class="mr-2"
                    @click="fnClose" icon="pi pi-arrow-circle-left" iconPos="left"></Button>
        </template>

    </PrimeDialog>
    <robot-dispose-component :entityId="entityId" :entityName="entityName" :isOpen="openDisposeWindow"
                             @onError="fnShowToast" @onDispose="fnDisposeEntity" @onClose="fnDisposeClose" />
    <robot-maintenance-component :entityId="entityId" :entityName="entityName" :isOpen="openMaintWindow"
                             @onError="fnShowToast" @onAddMaint="fnMaintenanceAdd" @onClose="fnMaintenanceClose" />
    <robot-work-component :entityId="entityId" :entityName="entityName" :isOpen="openWorkWindow"
                             @onError="fnShowToast" @onAddWork="fnWorkTimeAdded" @onClose="fnWorkedTimeClose" />
</template>

<script>
    import Button from 'primevue/button';
    import PrimeDialog from "primevue/dialog";
    import DataTable from 'primevue/datatable';
    import Column from 'primevue/column';
    
    import { ToastSeverity } from 'primevue/api';

    import Constants from "@/utils/Constants.js";
    import { robotService } from "@/services/RobotService.js";
    import { capitalizeFirstLetter, formatDate } from "@/utils/utils.js";
    import { formatNumber, formatPercentage } from "@/utils/utils.js";

    import LoaderMiniComponent from '@/components/LoaderMiniComponent'

    import RobotDisposeComponent from '@/components/Robots/RobotDisposeComponent'
    import RobotMaintenanceComponent from '@/components/Robots/RobotMaintenanceComponent'
    import RobotWorkComponent from '@/components/Robots/RobotWorkComponent'

    export default {
        name: "RobotDetailsComponent",
        emits: ["onError", "onClose", "onDispose", "onWorkAdded"],
        components: {
            Button,
            PrimeDialog,
            DataTable, Column,
            LoaderMiniComponent,
            RobotDisposeComponent,
            RobotMaintenanceComponent,
            RobotWorkComponent
        },
        props: {
            entityId: String,
            entityName: String,
            isOpen: Boolean
        },
        data() {
            return {
                loading: false,
                entity: {
                    robotId: undefined,
                    disposed: false,
                    reason: "",
                    user: undefined,
                    dispose: {
                        reason: "",
                        disposeDate: undefined,
                        disposeType: undefined
                    },
                    workLog: []
                },
                confirmObj: {
                    message: "",
                    isOpen: false
                },
                disposeStatus: [
                    { name: 'Failed', code: 'failed' },
                    { name: 'End of lifetime', code: 'lifetime-end' }
                ],
                //textHeader: "",
                disposeTypes: Constants.Asset.DISPOSE_TYPE,
                visible: false,
                openDisposeWindow: false,
                openWorkWindow: false,
                openMaintWindow: false
            }
        },
        watch: {
            isOpen: function (val) {
                if (val == true) {
                    console.log("Dispose Robot Component Opened!");
                    this.entity.robotId = this.entityId;
                    //this.textHeader = "Dispose Robot: " + this.entityName;
                    this.fnLoadEntity();
                    this.visible = this.isOpen;
                }
            }
        },
        methods: {
            fnLoadEntity() {
                this.loading = true;
                robotService.get(this.entityId)
                    .then((data) => {
                        console.log("Robot data loaded: ");
                        console.log(data);
                        this.entity = data;
                        this.loading = false;
                    })
                    .catch((error) => {
                        console.log(error);
                        this.$emit('Error', 'An error occurred when trying to dispose the Robot: "' + this.entityName + '"!!!', ToastSeverity.ERROR);
                        this.loading = false;
                    });
            },
            fnClose() {
                this.fnCleanForm();
                this.visible = false;
                this.$emit('onClose');
            },
            fnCleanForm: function () {
                this.entity = {
                    robotId: undefined,
                    disposed: false,
                    reason: "",
                    user: undefined,
                    dispose: {
                        reason: "",
                        disposeDate: undefined,
                        disposeType: undefined
                    },
                    workLog: []
                };
                this.textHeader = "";
            },
            fnMaintenanceOpen() {
                this.openMaintWindow = true;
            },
            fnMaintenanceClose() {
                this.openMaintWindow = false;
            },
            fnMaintenanceAdd(result) {
                //console.log(result);
                this.fnShowToast('Maintenace Success', 'The maintenance record have been added to the robot "' + this.entityName + '".', ToastSeverity.SUCCESS);
                if (this.entity.maintenanceLog != undefined && !Array.isArray(this.entity.maintenanceLog))
                    this.entity.maintenanceLog = [];

                this.entity.maintenanceLog.push(result); 

                //alert("Maintenance Add, change Robot status.");
                this.fnMaintenanceClose();
            },
            fnWorkedTimeOpen() {
                this.openWorkWindow = true;
            },
            fnWorkedTimeClose() {
                console.log("fnWorkedTimeClose.");
                this.openWorkWindow = false;
            },
            fnWorkTimeAdded(result) {
                
                this.fnShowToast('Add Work Success', 'The working hours have been added to the Robot "' + this.entityName + '".', ToastSeverity.SUCCESS);
                
                if (this.entity["workLog"] != undefined && !Array.isArray(this.entity["workLog"]))
                    this.entity["workLog"] = [];

                this.entity.workLog.push(result); 

                this.entity.operationalTime += result.workHours;
                this.entity.lifetimeLeft -= result.workHours;
                this.entity.lifetimeEstimated -= result.workHours;

                console.log("Add work to Robot.");
                this.$emit("onWorkAdded", result);

                this.fnWorkedTimeClose();
                
            },
            fnDisposeOpen() {
                this.openDisposeWindow = true;
            },
            fnDisposeClose() {
                this.openDisposeWindow = false;
            },
            fnDisposeEntity(dispose) {
                //console.log(dispose);
                this.fnShowToast('Dispose Success', 'The robot "' + this.entityName + '" was disposed!', ToastSeverity.WARN);
                
                this.entity.disposed = true;
                this.entity.dispose = dispose;
                
                //alert("Dispose Robot actions.");
                this.$emit("onDispose", dispose, this.alertName);
                this.fnDisposeClose();
            },
            fnShowToast(title, message, severity) {
                this.$toast.add({ severity: severity, summary: title, detail: message, life: 6000 });
            },
            fnShowMessage(title, message, severity) {
                this.$toast.add({ severity: severity, summary: title, detail: message, life: 30000 });
            },
            fnCapText(text) {
                return capitalizeFirstLetter(text);
            },
            fnFormatNumber(number) {
                return formatNumber(number);
            },
            fnFormatPercentaje(number) {
                return formatPercentage(number);
            },
            fnFormatDate(dateValue) {
                return formatDate(dateValue);
            },
            fnFormatTextDate(textValue) {
                return formatDate(new Date(textValue));
            }
        }
    };
</script>
<style scoped>
</style>

